//
// Select 2
//

.select2-container {
    .select2-selection--single {
        border: $input-border-width solid $input-border-color;
        height: $input-height;
        background-color: $input-bg;
        box-shadow: $components-shadow-sm;
        outline: none;
        .select2-selection__rendered {
            line-height: 36px;
            padding-left: 12px;
            color: $input-color;
            text-align: left;
        }
        .select2-selection__arrow {
            height: 34px;
            width: 34px;
            right: 3px;
            b {
                border-color: #{map-get($grays, "500")} transparent transparent transparent;
                border-width: 6px 6px 0 6px;
            }
        }
    }

    input::placeholder {
        color: $input-placeholder-color;
    }
}

.select2-container--open {
    .select2-selection--single {
        .select2-selection__arrow {
            b {
                border-color: transparent transparent #{map-get($grays, "500")} transparent !important;
                border-width: 0 6px 6px 6px !important;
            }
        }
    }
}

.select2-results__option {
    padding: 6px 12px;
    text-align: left;
}

.select2-dropdown {
    border: $dropdown-border-width solid $dropdown-border-color;
    box-shadow: $box-shadow;
    background-color: $dropdown-bg;
}

.select2-container--default {
    .select2-search--dropdown {
        padding: 10px;
        background-color: $dropdown-bg;
        .select2-search__field {
            outline: none;
            border: 1px solid $input-border-color;
            background-color: $input-bg;
            color: $input-color;
            text-align: left;
        }
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $primary;
    }
    .select2-results__option[aria-selected="true"] {
        background-color: $dropdown-bg;
        color: $dropdown-link-active-color;
        &:hover {
            background-color: $primary;
            color: $white;
        }
    }
}

.select2-container {
    .select2-selection--multiple {
        min-height: $input-height;
        border: $input-border-width solid $input-border-color !important;
        background-color: $input-bg;
        box-shadow: $components-shadow-sm;

        .select2-selection__rendered {
            padding: 1px 10px;
        }
        .select2-search__field {
            border: 0;
            color: $input-color;
        }
        .select2-selection__choice {
            background-color: $primary;
            border: none;
            color: $white;
            border-radius: 3px;
            padding: 0 7px;
            margin-top: 6px;
        }
        .select2-selection__choice__remove {
            color: $white;
            margin-right: 5px;
            &:hover {
                color: $white;
            }
        }
    }
    .select2-search--inline {
        .select2-search__field {
            margin-top: 7px;
        }
    }
}

.select2-selection {
    overflow: hidden;
}
.select2-selection__rendered {
    white-space: normal;
    word-break: break-all;
}

// Dark Mode
body[data-layout-color="dark"] {
    .select2-container {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: #{map-get($dark-grays, "500")} transparent transparent
                        transparent;
                }
            }
        }
    }
    .select2-container--open {
        .select2-selection--single {
            .select2-selection__arrow {
                b {
                    border-color: transparent transparent #{map-get(
                            $dark-grays,
                            "500"
                        )} transparent !important;
                }
            }
        }
    }
}
