@font-face {
    font-family: "feather";
    src: url("../../../../fonts/feather.eot?t=1525787366991"); /* IE9*/
    src: url("../../../../fonts/feather.eot?t=1525787366991#iefix")
            format("embedded-opentype"),
        /* IE6-IE8 */ url("../../../../fonts/feather.woff?t=1525787366991")
            format("woff"),
        /* chrome, firefox */
            url("../../../../fonts/feather.ttf?t=1525787366991")
            format("truetype"),
        /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
            url("../../../../fonts/feather.svg?t=1525787366991#feather")
            format("svg"); /* iOS 4.1- */
}

[class^="fe-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: "feather" !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.fe-alert-octagon:before {
    content: "\e81b";
}

.fe-alert-circle:before {
    content: "\e81c";
}

.fe-activity:before {
    content: "\e81d";
}

.fe-alert-triangle:before {
    content: "\e81e";
}

.fe-align-center:before {
    content: "\e81f";
}

.fe-airplay:before {
    content: "\e820";
}

.fe-align-justify:before {
    content: "\e821";
}

.fe-align-left:before {
    content: "\e822";
}

.fe-align-right:before {
    content: "\e823";
}

.fe-arrow-down-left:before {
    content: "\e824";
}

.fe-arrow-down-right:before {
    content: "\e825";
}

.fe-anchor:before {
    content: "\e826";
}

.fe-aperture:before {
    content: "\e827";
}

.fe-arrow-left:before {
    content: "\e828";
}

.fe-arrow-right:before {
    content: "\e829";
}

.fe-arrow-down:before {
    content: "\e82a";
}

.fe-arrow-up-left:before {
    content: "\e82b";
}

.fe-arrow-up-right:before {
    content: "\e82c";
}

.fe-arrow-up:before {
    content: "\e82d";
}

.fe-award:before {
    content: "\e82e";
}

.fe-bar-chart:before {
    content: "\e82f";
}

.fe-at-sign:before {
    content: "\e830";
}

.fe-bar-chart-2:before {
    content: "\e831";
}

.fe-battery-charging:before {
    content: "\e832";
}

.fe-bell-off:before {
    content: "\e833";
}

.fe-battery:before {
    content: "\e834";
}

.fe-bluetooth:before {
    content: "\e835";
}

.fe-bell:before {
    content: "\e836";
}

.fe-book:before {
    content: "\e837";
}

.fe-briefcase:before {
    content: "\e838";
}

.fe-camera-off:before {
    content: "\e839";
}

.fe-calendar:before {
    content: "\e83a";
}

.fe-bookmark:before {
    content: "\e83b";
}

.fe-box:before {
    content: "\e83c";
}

.fe-camera:before {
    content: "\e83d";
}

.fe-check-circle:before {
    content: "\e83e";
}

.fe-check:before {
    content: "\e83f";
}

.fe-check-square:before {
    content: "\e840";
}

.fe-cast:before {
    content: "\e841";
}

.fe-chevron-down:before {
    content: "\e842";
}

.fe-chevron-left:before {
    content: "\e843";
}

.fe-chevron-right:before {
    content: "\e844";
}

.fe-chevron-up:before {
    content: "\e845";
}

.fe-chevrons-down:before {
    content: "\e846";
}

.fe-chevrons-right:before {
    content: "\e847";
}

.fe-chevrons-up:before {
    content: "\e848";
}

.fe-chevrons-left:before {
    content: "\e849";
}

.fe-circle:before {
    content: "\e84a";
}

.fe-clipboard:before {
    content: "\e84b";
}

.fe-chrome:before {
    content: "\e84c";
}

.fe-clock:before {
    content: "\e84d";
}

.fe-cloud-lightning:before {
    content: "\e84e";
}

.fe-cloud-drizzle:before {
    content: "\e84f";
}

.fe-cloud-rain:before {
    content: "\e850";
}

.fe-cloud-off:before {
    content: "\e851";
}

.fe-codepen:before {
    content: "\e852";
}

.fe-cloud-snow:before {
    content: "\e853";
}

.fe-compass:before {
    content: "\e854";
}

.fe-copy:before {
    content: "\e855";
}

.fe-corner-down-right:before {
    content: "\e856";
}

.fe-corner-down-left:before {
    content: "\e857";
}

.fe-corner-left-down:before {
    content: "\e858";
}

.fe-corner-left-up:before {
    content: "\e859";
}

.fe-corner-up-left:before {
    content: "\e85a";
}

.fe-corner-up-right:before {
    content: "\e85b";
}

.fe-corner-right-down:before {
    content: "\e85c";
}

.fe-corner-right-up:before {
    content: "\e85d";
}

.fe-cpu:before {
    content: "\e85e";
}

.fe-credit-card:before {
    content: "\e85f";
}

.fe-crosshair:before {
    content: "\e860";
}

.fe-disc:before {
    content: "\e861";
}

.fe-delete:before {
    content: "\e862";
}

.fe-download-cloud:before {
    content: "\e863";
}

.fe-download:before {
    content: "\e864";
}

.fe-droplet:before {
    content: "\e865";
}

.fe-edit-2:before {
    content: "\e866";
}

.fe-edit:before {
    content: "\e867";
}

.fe-edit-1:before {
    content: "\e868";
}

.fe-external-link:before {
    content: "\e869";
}

.fe-eye:before {
    content: "\e86a";
}

.fe-feather:before {
    content: "\e86b";
}

.fe-facebook:before {
    content: "\e86c";
}

.fe-file-minus:before {
    content: "\e86d";
}

.fe-eye-off:before {
    content: "\e86e";
}

.fe-fast-forward:before {
    content: "\e86f";
}

.fe-file-text:before {
    content: "\e870";
}

.fe-film:before {
    content: "\e871";
}

.fe-file:before {
    content: "\e872";
}

.fe-file-plus:before {
    content: "\e873";
}

.fe-folder:before {
    content: "\e874";
}

.fe-filter:before {
    content: "\e875";
}

.fe-flag:before {
    content: "\e876";
}

.fe-globe:before {
    content: "\e877";
}

.fe-grid:before {
    content: "\e878";
}

.fe-heart:before {
    content: "\e879";
}

.fe-home:before {
    content: "\e87a";
}

.fe-github:before {
    content: "\e87b";
}

.fe-image:before {
    content: "\e87c";
}

.fe-inbox:before {
    content: "\e87d";
}

.fe-layers:before {
    content: "\e87e";
}

.fe-info:before {
    content: "\e87f";
}

.fe-instagram:before {
    content: "\e880";
}

.fe-layout:before {
    content: "\e881";
}

.fe-link-2:before {
    content: "\e882";
}

.fe-life-buoy:before {
    content: "\e883";
}

.fe-link:before {
    content: "\e884";
}

.fe-log-in:before {
    content: "\e885";
}

.fe-list:before {
    content: "\e886";
}

.fe-lock:before {
    content: "\e887";
}

.fe-log-out:before {
    content: "\e888";
}

.fe-loader:before {
    content: "\e889";
}

.fe-mail:before {
    content: "\e88a";
}

.fe-maximize-2:before {
    content: "\e88b";
}

.fe-map:before {
    content: "\e88c";
}

.fe-map-pin:before {
    content: "\e88e";
}

.fe-menu:before {
    content: "\e88f";
}

.fe-message-circle:before {
    content: "\e890";
}

.fe-message-square:before {
    content: "\e891";
}

.fe-minimize-2:before {
    content: "\e892";
}

.fe-mic-off:before {
    content: "\e893";
}

.fe-minus-circle:before {
    content: "\e894";
}

.fe-mic:before {
    content: "\e895";
}

.fe-minus-square:before {
    content: "\e896";
}

.fe-minus:before {
    content: "\e897";
}

.fe-moon:before {
    content: "\e898";
}

.fe-monitor:before {
    content: "\e899";
}

.fe-more-vertical:before {
    content: "\e89a";
}

.fe-more-horizontal:before {
    content: "\e89b";
}

.fe-move:before {
    content: "\e89c";
}

.fe-music:before {
    content: "\e89d";
}

.fe-navigation-2:before {
    content: "\e89e";
}

.fe-navigation:before {
    content: "\e89f";
}

.fe-octagon:before {
    content: "\e8a0";
}

.fe-package:before {
    content: "\e8a1";
}

.fe-pause-circle:before {
    content: "\e8a2";
}

.fe-pause:before {
    content: "\e8a3";
}

.fe-percent:before {
    content: "\e8a4";
}

.fe-phone-call:before {
    content: "\e8a5";
}

.fe-phone-forwarded:before {
    content: "\e8a6";
}

.fe-phone-missed:before {
    content: "\e8a7";
}

.fe-phone-off:before {
    content: "\e8a8";
}

.fe-phone-incoming:before {
    content: "\e8a9";
}

.fe-phone:before {
    content: "\e8aa";
}

.fe-phone-outgoing:before {
    content: "\e8ab";
}

.fe-pie-chart:before {
    content: "\e8ac";
}

.fe-play-circle:before {
    content: "\e8ad";
}

.fe-play:before {
    content: "\e8ae";
}

.fe-plus-square:before {
    content: "\e8af";
}

.fe-plus-circle:before {
    content: "\e8b0";
}

.fe-plus:before {
    content: "\e8b1";
}

.fe-pocket:before {
    content: "\e8b2";
}

.fe-printer:before {
    content: "\e8b3";
}

.fe-power:before {
    content: "\e8b4";
}

.fe-radio:before {
    content: "\e8b5";
}

.fe-repeat:before {
    content: "\e8b6";
}

.fe-refresh-ccw:before {
    content: "\e8b7";
}

.fe-rewind:before {
    content: "\e8b8";
}

.fe-rotate-ccw:before {
    content: "\e8b9";
}

.fe-refresh-cw:before {
    content: "\e8ba";
}

.fe-rotate-cw:before {
    content: "\e8bb";
}

.fe-save:before {
    content: "\e8bc";
}

.fe-search:before {
    content: "\e8bd";
}

.fe-server:before {
    content: "\e8be";
}

.fe-scissors:before {
    content: "\e8bf";
}

.fe-share-2:before {
    content: "\e8c0";
}

.fe-share:before {
    content: "\e8c1";
}

.fe-shield:before {
    content: "\e8c2";
}

.fe-settings:before {
    content: "\e8c3";
}

.fe-skip-back:before {
    content: "\e8c4";
}

.fe-shuffle:before {
    content: "\e8c5";
}

.fe-sidebar:before {
    content: "\e8c6";
}

.fe-skip-forward:before {
    content: "\e8c7";
}

.fe-slack:before {
    content: "\e8c8";
}

.fe-slash:before {
    content: "\e8c9";
}

.fe-smartphone:before {
    content: "\e8ca";
}

.fe-square:before {
    content: "\e8cb";
}

.fe-speaker:before {
    content: "\e8cc";
}

.fe-star:before {
    content: "\e8cd";
}

.fe-stop-circle:before {
    content: "\e8ce";
}

.fe-sun:before {
    content: "\e8cf";
}

.fe-sunrise:before {
    content: "\e8d0";
}

.fe-tablet:before {
    content: "\e8d1";
}

.fe-tag:before {
    content: "\e8d2";
}

.fe-sunset:before {
    content: "\e8d3";
}

.fe-target:before {
    content: "\e8d4";
}

.fe-thermometer:before {
    content: "\e8d5";
}

.fe-thumbs-up:before {
    content: "\e8d6";
}

.fe-thumbs-down:before {
    content: "\e8d7";
}

.fe-toggle-left:before {
    content: "\e8d8";
}

.fe-toggle-right:before {
    content: "\e8d9";
}

.fe-trash-2:before {
    content: "\e8da";
}

.fe-trash:before {
    content: "\e8db";
}

.fe-trending-up:before {
    content: "\e8dc";
}

.fe-trending-down:before {
    content: "\e8dd";
}

.fe-triangle:before {
    content: "\e8de";
}

.fe-type:before {
    content: "\e8df";
}

.fe-twitter:before {
    content: "\e8e0";
}

.fe-upload:before {
    content: "\e8e1";
}

.fe-umbrella:before {
    content: "\e8e2";
}

.fe-upload-cloud:before {
    content: "\e8e3";
}

.fe-unlock:before {
    content: "\e8e4";
}

.fe-user-check:before {
    content: "\e8e5";
}

.fe-user-minus:before {
    content: "\e8e6";
}

.fe-user-plus:before {
    content: "\e8e7";
}

.fe-user-x:before {
    content: "\e8e8";
}

.fe-user:before {
    content: "\e8e9";
}

.fe-users:before {
    content: "\e8ea";
}

.fe-video-off:before {
    content: "\e8eb";
}

.fe-video:before {
    content: "\e8ec";
}

.fe-voicemail:before {
    content: "\e8ed";
}

.fe-volume-x:before {
    content: "\e8ee";
}

.fe-volume-2:before {
    content: "\e8ef";
}

.fe-volume-1:before {
    content: "\e8f0";
}

.fe-volume:before {
    content: "\e8f1";
}

.fe-watch:before {
    content: "\e8f2";
}

.fe-wifi:before {
    content: "\e8f3";
}

.fe-x-square:before {
    content: "\e8f4";
}

.fe-wind:before {
    content: "\e8f5";
}

.fe-x:before {
    content: "\e8f6";
}

.fe-x-circle:before {
    content: "\e8f7";
}

.fe-zap:before {
    content: "\e8f8";
}

.fe-zoom-in:before {
    content: "\e8f9";
}

.fe-zoom-out:before {
    content: "\e8fa";
}

.fe-command:before {
    content: "\e8fb";
}

.fe-cloud:before {
    content: "\e8fc";
}

.fe-hash:before {
    content: "\e8fd";
}

.fe-headphones:before {
    content: "\e8fe";
}

.fe-underline:before {
    content: "\e8ff";
}

.fe-italic:before {
    content: "\e900";
}

.fe-bold:before {
    content: "\e901";
}

.fe-crop:before {
    content: "\e902";
}

.fe-help-circle:before {
    content: "\e903";
}

.fe-paperclip:before {
    content: "\e904";
}

.fe-shopping-cart:before {
    content: "\e905";
}

.fe-tv:before {
    content: "\e906";
}

.fe-wifi-off:before {
    content: "\e907";
}

.fe-minimize:before {
    content: "\e88d";
}

.fe-maximize:before {
    content: "\e908";
}

.fe-gitlab:before {
    content: "\e909";
}

.fe-sliders:before {
    content: "\e90a";
}

.fe-star-on:before {
    content: "\e90b";
}

.fe-heart-on:before {
    content: "\e90c";
}

.fe-archive:before {
    content: "\e90d";
}

.fe-arrow-down-circle:before {
    content: "\e90e";
}

.fe-arrow-up-circle:before {
    content: "\e90f";
}

.fe-arrow-left-circle:before {
    content: "\e910";
}

.fe-arrow-right-circle:before {
    content: "\e911";
}

.fe-bar-chart-line-:before {
    content: "\e912";
}

.fe-bar-chart-line:before {
    content: "\e913";
}

.fe-book-open:before {
    content: "\e914";
}

.fe-code:before {
    content: "\e915";
}

.fe-database:before {
    content: "\e916";
}

.fe-dollar-sign:before {
    content: "\e917";
}

.fe-folder-plus:before {
    content: "\e918";
}

.fe-gift:before {
    content: "\e919";
}

.fe-folder-minus:before {
    content: "\e91a";
}

.fe-git-commit:before {
    content: "\e91b";
}

.fe-git-branch:before {
    content: "\e91c";
}

.fe-git-pull-request:before {
    content: "\e91d";
}

.fe-git-merge:before {
    content: "\e91e";
}

.fe-linkedin:before {
    content: "\e91f";
}

.fe-hard-drive:before {
    content: "\e920";
}

.fe-more-vertical-:before {
    content: "\e921";
}

.fe-more-horizontal-:before {
    content: "\e922";
}

.fe-rss:before {
    content: "\e923";
}

.fe-send:before {
    content: "\e924";
}

.fe-shield-off:before {
    content: "\e925";
}

.fe-shopping-bag:before {
    content: "\e926";
}

.fe-terminal:before {
    content: "\e927";
}

.fe-truck:before {
    content: "\e928";
}

.fe-zap-off:before {
    content: "\e929";
}

.fe-youtube:before {
    content: "\e92a";
}
