//
// utilities.scss
//

@each $color, $value in $theme-colors {
    // bg color
    .bg-#{$color} {
        background-color: $value !important;
    }

    // bg-soft color
    .bg-soft-#{$color} {
        background-color: rgba(($value), 0.25) !important;
    }

    // border color
    .border-#{$color} {
        border-color: $value !important;
    }

    // text color
    .text-#{$color} {
        color: $value !important;
    }
}

.text-body {
    color: $body-color !important;
}

.bg-ghost {
    opacity: 0.4;
}

body[data-layout-color="dark"] {
    @each $color, $value in $dark-theme-colors {
        // bg color
        .bg-#{$color} {
            background-color: $value !important;
        }

        // bg-soft color
        .bg-soft-#{$color} {
            background-color: rgba(($value), 0.25) !important;
        }

        // border color
        .border-#{$color} {
            border-color: $value !important;
        }

        // text color
        .text-#{$color} {
            color: $value !important;
        }
    }
    .text-body {
        color: var(--#{$variable-prefix}body-color) !important;
    }
}
