.yarl__thumbnails_vignette {
    --yarl__thumbnails_vignette_size: "0%";
}

.yarl__thumbnails_bottom .yarl__thumbnails_vignette,
.yarl__thumbnails_top .yarl__thumbnails_vignette {
    background: none;
}

.yarl__thumbnails_thumbnail_active {
    border: 2px solid #3bafda;
}
