//
// sweetalert.scss
//

.swal2-modal {
    font-family: $font-family-base;
    box-shadow: 0 10px 33px rgba($black, 0.1);

    &.swal2-popup {
        background-color: $card-bg;
    }

    .swal2-footer {
        border-top: $border-width solid $border-color;
    }

    .swal2-html-container {
        color: $body-color;
    }
    .swal2-title {
        font-size: 24px;
    }

    .swal2-content {
        font-size: 16px;
    }

    .swal2-spacer {
        margin: 10px 0;
    }

    .swal2-file,
    .swal2-input,
    .swal2-textarea {
        border: 2px solid #{map-get($grays, "300")};
        font-size: 16px;
        box-shadow: none;
    }

    .swal2-confirm {
        background-color: $primary !important;
        font-size: $font-size-base !important;
    }

    .swal2-cancel.btn-cancel {
        background-color: $danger !important;
        font-size: $font-size-base;
    }

    .swal2-confirm,
    .swal2-cancel {
        margin: 0.3125em;
        padding: 0.625em 2em;
        font-weight: 500;
        box-shadow: none;
        font-size: $btn-font-size !important;
        &:focus {
            box-shadow: none !important;
        }
    }

    .swal2-file:focus,
    .swal2-input:focus,
    .swal2-textarea:focus {
        outline: 0;
        border: 2px solid $primary;
    }
}

.swal2-icon {
    &.swal2-question {
        color: $primary !important;
        border-color: $primary !important;
    }

    &.swal2-success {
        border-color: $success;

        .line,
        [class^="swal2-success-line"][class$="long"],
        [class^="swal2-success-line"] {
            background-color: $success !important;
        }

        .placeholder,
        .swal2-success-ring {
            border-color: $success !important;
        }
    }

    &.swal2-warning {
        color: $warning !important;
        border-color: $warning !important;
    }

    &.swal2-error {
        border-color: $danger !important;

        .line {
            background-color: $danger !important;
        }
    }

    &.swal2-info {
        border-color: $info;
        color: $info;
    }
}

.swal2-actions {
    margin: 1.6em auto 0 !important;
}

.swal2-container {
    &.swal2-backdrop-show,
    &.swal2-noanimation {
        background-color: rgba(
            $modal-backdrop-bg,
            $modal-backdrop-opacity
        ) !important;
    }
}

body {
    &.swal2-shown {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }
}

// dark mode
body[data-layout-color="dark"] {
    .swal2-modal {
        .swal2-file,
        .swal2-input,
        .swal2-textarea {
            border: 2px solid #{map-get($dark-grays, "300")};
        }
    }
}
