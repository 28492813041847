//
// custom-variables
//

//Background left-sidebar
$bg-leftbar: var(--#{$variable-prefix}bg-leftbar);
$bg-leftbar-dark: var(--#{$variable-prefix}bg-leftbar-dark);
$bg-leftbar-brand: var(--#{$variable-prefix}bg-leftbar-brand);
$bg-leftbar-gradient: var(--#{$variable-prefix}bg-leftbar-gradient);

// Leftbar width
$leftbar-width: 240px;
$leftbar-width-condensed: 70px;

// Small Leftbar Width
$leftbar-width-sm: 160px;

// Boxed layout width
$boxed-layout-width: 1300px;

// Compact sidebar
$twocolumn-sidebar-width: 220px;
$twocolumn-sidebar-iconview-width: 70px;
$twocolumn-sidebar-bg: var(--#{$variable-prefix}twocolumn-sidebar-bg);
$twocolumn-sidebar-iconview-bg: var(
    --#{$variable-prefix}twocolumn-sidebar-iconview-bg
);

// Dark leftbar menu color
$menu-item-color-dark: var(--#{$variable-prefix}menu-item-color-dark);
$menu-item-hover-color-dark: var(
    --#{$variable-prefix}menu-item-hover-color-dark
);
$menu-item-active-color-dark: var(
    --#{$variable-prefix}menu-item-active-color-dark
);

//Menu item
$menu-item: var(--#{$variable-prefix}menu-item);
$menu-item-hover: var(--#{$variable-prefix}menu-item-hover);
$menu-item-active: var(--#{$variable-prefix}menu-item-active);
$menu-sub-item-active: var(--#{$variable-prefix}menu-sub-item-active);
$menu-item-size: 0.95rem;
$menu-sub-item-size: 0.875rem;

$menuitem-active-bg: var(--#{$variable-prefix}menuitem-active-bg);

// Horizontal Menu Item
$hori-menu-item-color: var(--#{$variable-prefix}hori-menu-item-color);

//Rightbar Width
$rightbar-width: 260px;
$rightbar-bg: var(--#{$variable-prefix}rightbar-bg);

//Topbar Background
$bg-topbar-light: var(--#{$variable-prefix}bg-topbar-light);
$bg-topbar-dark: var(--#{$variable-prefix}bg-topbar-dark);

// Leftbar item shadow (Condensed item)
$box-shadow-condensed: var(--#{$variable-prefix}box-shadow-condensed);

// Footer
$footer-bg: var(--#{$variable-prefix}footer-bg);

// Topnav Height (Horizontal)
$topnav-height: 55px;

// Topbar Height
$topbar-height: 70px;

// Horizontal Layout
$horizontal-layout-width: 90%;

// Body minimum height
$body-min-height: 1750px;

// Secondary font
$font-family-secondary: "Cerebri Sans,sans-serif";

// Font weight
$font-weight-medium: 500;
$font-weight-semibold: 600;

// Dropdown Large
$dropdown-lg-width: 320px;

// Logo Display
$logo-dark-display: none;
$logo-light-display: block;

// Logo Display - Auth Pages
$logo-auth-dark-display: block;
$logo-auth-light-display: none;

// User authentication Background
$auth-bg: var(--#{$variable-prefix}auth-bg);
$auth-bg-alt: var(--#{$variable-prefix}auth-bg-alt);
$auth-img-pattern-bg: url("../../../images/bg-pattern.png");

// Apex chart
$apex-grid-color: var(--#{$variable-prefix}apex-grid-color);

// Chat widget
$chat-primary-user-bg: var(--#{$variable-prefix}chat-primary-user-bg);
$chat-secondary-user-bg: var(--#{$variable-prefix}chat-secondary-user-bg);

// Text-color (h1 to h6)
$text-color-h: var(--#{$variable-prefix}text-color-h);

// Card overlay
$card-overlay-color: var(--#{$variable-prefix}card-overlay-color);
$card-overlay-bg: var(--#{$variable-prefix}card-overlay-bg);

// Input light gray
$input-light-bg: var(--#{$variable-prefix}input-light-bg);

// Components Shadows
$components-shadow-sm: var(--#{$variable-prefix}components-shadow-sm);
$components-shadow: var(--#{$variable-prefix}components-shadow);
$components-shadow-lg: var(--#{$variable-prefix}components-shadow-lg);

// label
$label-font-weight: 600;

// Table Head
$table-head-font-weight: 700;

// landing section bg
$section-bg: var(--#{$variable-prefix}section-bg);
$section-light-bg: var(--#{$variable-prefix}section-light-bg);
// landing-img color
$img-color: var(--#{$variable-prefix}img-color);
