//
// pagination.scss
//

// Pagination rounded (Custom)
.pagination-rounded {
    .page-link {
        border-radius: 30px !important;
        margin: 0 6px;
        border: none;
    }
    .page-item {
        &:last-child {
            .page-link {
                margin-right: 0px;
            }
        }
    }
}

.pagination {
    a {
        line-height: $line-height-sm !important;
    }
}
